var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.totalImages > 0)?_c('div',{class:['DxpCardCarousel'],style:({
    width: `${_vm.componentWidth}rem;`,
  })},[_c('div',{staticClass:"DxpCardCarousel__PreviewContainer"},[_c('div',{staticClass:"DxpCardCarousel__Wrapper"},[_c('div',{ref:"image",class:['DxpCardCarousel__Image']},[_c('mom-grid-container',{staticClass:"DxpCardCarousel__ImageWrapper"},[_c('mom-grid-column',{attrs:{"size-lg":"7","size":"12"}},[_c('div',{staticClass:"DxpCardCarousel__Content"},[_c('div',[_c('h1',{staticClass:"mom-h1"},[_vm._v(_vm._s(_vm.currentImage.title))]),_c('p',{staticClass:"DxpCardCarousel__Content__Text"},[_vm._v(" "+_vm._s(_vm.currentImage.text)+" ")]),_c('mom-button',{staticClass:"DxpButton",attrs:{"text":_vm.currentImage.buttonLabel},on:{"click":function($event){return _vm.$router.push(_vm.currentImage.link)}}})],1)])]),_c('mom-grid-column',{attrs:{"size-lg":"5","size":"12"}},[(_vm.currentImage.url)?_c('div',[(_vm.isSvg)?_c('figure',{staticClass:"DxpCardCarousel__Image",style:({
                  transform: `scale(${_vm.scale}) translate(${_vm.translateX}px, ${_vm.translateY}px)`,
                }),attrs:{"id":"carousel_image_current"},domProps:{"innerHTML":_vm._s(_vm.currentImage.url)},on:{"click":function($event){return _vm.$router.push(_vm.currentImage.link)}}}):_c('img',{staticClass:"DxpCardCarousel__Image",style:({
                  transform: `scale(${_vm.scale}) translate(${_vm.translateX}px, ${_vm.translateY}px)`,
                }),attrs:{"id":"carousel_image_current","src":_vm.currentImage.url,"alt":_vm.currentImage.alt},on:{"click":function($event){return _vm.$router.push(_vm.currentImage.link)}}})]):_c('div',{staticClass:"DxpCardCarousel__Image__SpinnerContainer"},[_c('MomSpinner')],1)])],1)],1)]),(_vm.currentImageIndex > 0)?_c('button',{staticClass:"DxpCardCarousel__PrevButton",on:{"click":function($event){return _vm.updateImage(_vm.currentImageIndex - 1)}}},[_c('mom-icon',{attrs:{"icon":"chevron-left","size":"l"}})],1):_vm._e(),(_vm.currentImageIndex < _vm.totalImages - 1)?_c('button',{staticClass:"DxpCardCarousel__NextButton",on:{"click":function($event){return _vm.updateImage(_vm.currentImageIndex + 1)}}},[_c('mom-icon',{attrs:{"icon":"chevron-right","size":"l"}})],1):_vm._e()]),(_vm.currentImage.description)?_c('div',{staticClass:"DxpCardCarousel__DescriptionWrapper"},[_c('p',{staticClass:"mom-p"},[_vm._v(_vm._s(_vm.currentImage.description))])]):_vm._e()]):_c('div',{staticClass:"DxpCardCarousel__Placeholder",style:({
    width: `${_vm.componentWidth}rem`,
  })},[_c('p',{staticClass:"DxpCardCarousel__PlaceholderText mom-p-s"},[_vm._v(" Only jpg and png files will display a preview. ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }