import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import preventIFrame from "./utils/preventIFrame";
import "./scss/carapace.css";
import "./scss/global.scss";
import "@/utils/pageMetaDetails.js";

preventIFrame();

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
