export const headerConfig = {
  headerTabs: [
    // {
    //   title: "Home",
    //   pageUrl: "/",
    // },
    {
      title: "About",
      pageUrl: "#/About",
    },
    {
      title: "Getting started",
      pageUrl: "#/GettingStarted",
    },
    {
      title: "Playbook",
      pageUrl: "#/Playbook",
    },
    {
      title: "Components",
      pageUrl: "/components/index.html",
    },
    {
      title: "Templates",
      pageUrl: "/templates/index.html",
    },
    {
      title: "Tools",
      pageUrl: "#/Tools",
    },
  ],
  headerTabMoreIndex: 6,
  headerTabMore: {
    title: "More",
    children: [
      {
        title: "Community",
        pageUrl: "#/Community",
      },
      {
        title: "UX resources",
        pageUrl: "#/UxResources",
      },
      {
        title: "Mobile standards",
        pageUrl: "#/MobileStandards",
      },
      {
        title: "FAQ",
        pageUrl: "#/Faq",
      },
      {
        title: "Contact us",
        pageUrl: "#/Contact",
      },
      {
        title: "Release notes",
        pageUrl: "#/Release",
      },
    ],
  },
};

export const footerConfig = {
  footerSitemap: [
    {
      headerTitle: "Getting started",
      href: "#/GettingStarted",
      isDisabled: false,
      options: [
        {
          title: "Installing DX+",
          path: "#/GettingStarted",
          isDisabled: false,
        },
        {
          title: "Using DX+ Figma library",
          path: "#/GettingStarted/UsingLibrary",
          isDisabled: false,
        },
        {
          title: "Using DX+ playbook",
          path: "#/Playbook",
          isDisabled: false,
        },
      ],
    },
    {
      headerTitle: "Tools",
      href: "#/Tools",
      isDisabled: false,
      options: [
        {
          title: "Design and prototype",
          path: "#/Tools",
          isDisabled: false,
        },
        {
          title: "UI checker",
          path: "#/Tools",
          isDisabled: false,
        },
        {
          title: "UX metrics",
          path: "#/Tools",
          isDisabled: false,
        },
      ],
    },
    {
      headerTitle: "Others",
      href: "#",
      isDisabled: false,
      options: [
        {
          title: "UX resources",
          path: "#/UxResources",
          isDisabled: false,
        },
        {
          title: "Release notes",
          path: "#/Release",
          isDisabled: false,
        },
        {
          title: "Community",
          path: "#/Community",
          isDisabled: false,
        },
      ],
    },
  ],
  footerOthers: [
    {
      text: "Share your views @ REACH",
      href: "https://www.reach.gov.sg/",
      isDisabled: false,
      icon: "open-in-new",
      iconPosition: "right",
      target: "_blank",
      rel:"noreferrer"
    },
    {
      text: "www.gov.sg",
      href: "https://www.gov.sg/",
      isDisabled: false,
      icon: "open-in-new",
      iconPosition: "right",
      target: "_blank",
      rel:"noreferrer"
    },
    {
      text: "Contact us",
      href: "#/Contact",
      isDisabled: false,
    },
    {
      text: "Feedback",
      href: "https://dxplus.slack.com/archives/C01EA2BDAQM",
      isDisabled: false,
      icon: "open-in-new",
      iconPosition: "right",
      target: "_blank",
      rel:"noreferrer"
    },
    {
      text: "FAQ",
      href: "#/Faq",
      isDisabled: false,
    },
  ],
  momFooterSitemap: [
    {
      text: "Report vulnerability",
      href: "https://www.tech.gov.sg/report_vulnerability",
      icon: "open-in-new",
      rel:"noreferrer"
    },
    {
      text: "Privacy",
      href: "https://www.mom.gov.sg/privacy-statement",
      rel:"noreferrer"
    },
    {
      text: "Terms of use",
      href: "https://www.mom.gov.sg/terms-of-use",
      rel:"noreferrer"
    },
    {
      text: "Sitemap",
      href: "#/Sitemap",
      target: "_self",
    },
    {
      text: "Supported browsers",
      href: "#/SupportedBrowsers",
      target: "_self",
    },
  ],
};
