<template>
  <div class="DxpPageFooter">
    <div class="DxpPageFooter__PreCard">
      <!-- @slot Pre Card content -->
      <slot name="precard"></slot>
    </div>
    <div class="DxpPageFooter__Content">
      <mom-layout-wrapper>
        <a class="DxpPageFooter__Logo" href="/" rel="Ministry of Manpower">
          <figure
            class="DxpPageFooter__LogoImage"
            v-html="
              getImgSvg(
                'footer_logo.svg',
                'Dx+ Logo',
                '150',
                '70',
                '251',
                '101'
              )
            "
          />
        </a>
        <mom-grid-container class="DxpPageFooter__Sitemap" gutter-size="none">
          <mom-grid-column
            v-for="(item, index) in sitemap"
            :key="`sitemap_nav_${index}`"
            size="12"
            size-sm="12"
            size-md="4"
            size-lg="4"
            size-xl="4"
          >
            <div>
              <mom-link
                class="DxpPageFooter__SitemapList DxpPageFooter__Link"
                :href="item.href"
                @click="checkIfSameRoute(item.href)"
                :disabled="item.isDisabled"
              >
                <p class="mom-p">{{ item.headerTitle }}</p>
              </mom-link>
              <br />
              <div
                v-for="(link, i) in item.options"
                :key="`sitemap_nav_item_${item.title}_${i}`"
              >
                <mom-link
                  class="DxpPageFooter__SitemapListItem DxpPageFooter__Link"
                  :href="link.path"
                  @click="checkIfSameRoute(link.path)"
                  :disabled="link.isDisabled"
                  ><p class="DxpPageFooter__SitemapListItemText">
                    {{ link.title }}
                  </p></mom-link
                >
                <br />
              </div>
            </div>
          </mom-grid-column>
        </mom-grid-container>
        <div class="DxpPageFooter__Others">
          <mom-link
            size="m"
            v-for="(option, i) in footerOthers"
            :key="`footer_others_${i}`"
            :href="option.href"
            @click="checkIfSameRoute(option.href)"
            :disabled="option.isDisabled"
            class="DxpPageFooter__Link DxpPageFooter__OtherLink"
            :icon="option.icon"
            :icon-position="option.iconPosition"
            :target="option.target"
            :rel="option.rel"
          >
            <p class="DxpPageFooter__OthersItemText">
              {{ option.text }}
            </p>
          </mom-link>
        </div>
      </mom-layout-wrapper>
    </div>

    <mom-page-footer
      type="infoServices"
      slot="footer"
      last-updated-date="05 June 2024"
      :links="momFooterSitemap"
    />
  </div>
</template>

<script>
import { MomLayoutWrapper } from "cdpl/layout.esm";
import { MomLink } from "cdpl/link.esm";
import { MomGridContainer, MomGridColumn } from "cdpl/gridsystem.esm";
import { MomPageFooter } from "cdpl/page.esm";
import imageActions from "@/utils/imageActions";
import urlActions from "@/utils/urlActions";

export default {
  name: "DxpPageFooter",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  components: {
    MomLayoutWrapper,
    MomLink,
    MomGridColumn,
    MomGridContainer,
    MomPageFooter,
  },
  mixins: [imageActions, urlActions],
  props: {
    /**
     * Footer navigation links with options<br />
     * `{ headerTitle, href, options }`
     * `{ title, path }`
     */
    sitemap: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /**
     * Footer navigation other links <br />
     * `{ text, href}`
     */
    footerOthers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    momFooterSitemap: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.DxpPageFooter__PreCard {
  background-color: $color-white;
}

.DxpPageFooter__Content {
  background-color: $color-cool-grey-70;
  padding-top: rem(34);
  padding-bottom: rem(21);
  color: $color-white;
}

.DxpPageFooter__Sitemap {
  padding-top: rem(24);
}

.DxpPageFooter__Link {
  margin-bottom: rem(16);
  color: $color-white;

  &:visited {
    color: $color-purple-10;
    .DocsIcon {
      color: $color-purple-10;
    }
  }

  &:hover {
    color: $color-blue-10;
  }

  &:active,
  &:disabled {
    color: $color-white;
    // color: $color-blue-10 !important;
  }
}

.DxpPageFooter__SitemapList {
  @include font-weight(semibold);
}

.DxpPageFooter__Others {
  display: flex;
  flex-direction: column;

  @include min-width(tablet) {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: rem(24);
  }
}

.DxpPageFooter__OtherLink {
  @include font-size(m);
  @include line-height(m);
  color: $color-white;
  margin-bottom: rem(16);

  @include min-width(tablet) {
    margin-left: rem(40);
    margin-right: rem(40);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:visited {
    color: $color-purple-10;
    .DocsIcon {
      color: $color-purple-10;
    }
  }

  &:hover {
    color: $color-blue-10;
  }

  &:active {
    color: $color-white;
    // color: $color-blue-10 !important;
  }
}

.DxpPageFooter__LogoImage {
  margin-bottom: rem(38.4);
}
</style>

<style lang="scss">
.DxpPageFooter__Link {
  .MomIcon {
    color: $color-white !important;
    padding: rem(3.5);
  }

  .MomLink__Text {
    color: $color-white ;
  }

  &:hover {
    color: $color-blue-10;
    .MomIcon {
      color: $color-blue-10 !important;
    }

    .MomLink__Text {
      color: $color-blue-10 ;
    }
  }
}
</style>
