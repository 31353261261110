import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

var routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/GettingStarted",
    component: () =>
      import(
        /* webpackChunkName: "GettingStarted" */ `../views/GettingStarted.vue`
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "GettingStarted" */ `../views/GettingStarted/InstallingDx.vue`
          ),
        name: "InstallingDx",
      },
      {
        path: "UsingLibrary",
        component: () =>
          import(
            /* webpackChunkName: "GettingStarted" */ `../views/GettingStarted/UsingLibrary.vue`
          ),
        name: "UsingLibrary",
      },
      {
        path: "UsingCdpl",
        component: () =>
          import(
            /* webpackChunkName: "GettingStarted" */ `../views/GettingStarted/UsingCdpl.vue`
          ),
        name: "UsingCdpl",
      },
    ],
  },
  {
    path: "/UxResources",
    component: () =>
      import(/* webpackChunkName: "UxResources" */ `../views/UxResources.vue`),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/All.vue`
          ),
        name: "All",
      },
      {
        path: "Define",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Define.vue`
          ),
        name: "Define",
      },
      {
        path: "Plan",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Plan.vue`
          ),
        name: "UxResourcesPlan",
      },
      {
        path: "Validate",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Validate.vue`
          ),
        name: "UxResourcesValidate",
      },
      {
        path: "Measure",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Measure.vue`
          ),
        name: "UxResourcesMeasure",
      },
      {
        path: "Ideate",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Ideate.vue`
          ),
        name: "UxResourcesIdeate",
      },
      {
        path: "Discover",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Discover.vue`
          ),
        name: "UxResourcesDiscover",
      },
      {
        path: "Implement",
        component: () =>
          import(
            /* webpackChunkName: "UxResources" */ `../views/UxResources/Implement.vue`
          ),
        name: "UxResourcesImplement",
      },
    ],
  },
  {
    path: "/Playbook",
    component: () =>
      import(/* webpackChunkName: "Playbook" */ `../views/Playbook.vue`),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Overview.vue`
          ),
        name: "Playbook",
      },
      {
        path: "Plan",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Plan.vue`
          ),
        name: "PlaybookPlan",
      },
      {
        path: "Discover",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Discover.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/Playbook/Discover/Overview.vue`
              ),
            name: "Discover",
          },
          {
            path: "FocusGroup",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/Playbook/Discover/FocusGroup.vue`
              ),
            name: "FocusGroup",
          },
          {
            path: "Survey",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/Playbook/Discover/Survey.vue`
              ),
            name: "Survey",
          },
          {
            path: "StakeholderInterviews",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/Playbook/Discover/Stakeholder.vue`
              ),
            name: "StakeholderInterviews",
          },
        ],
      },
      {
        path: "Define",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Define.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Define" */ `../views/Playbook/Define/Overview.vue`
              ),
            name: "PlaybookDefine",
          },
          {
            path: "Personas",
            component: () =>
              import(
                /* webpackChunkName: "Define" */ `../views/Playbook/Define/Personas.vue`
              ),
            name: "Personas",
          },
          {
            path: "UserScenarios",
            component: () =>
              import(
                /* webpackChunkName: "Define" */ `../views/Playbook/Define/UserScenarios.vue`
              ),
            name: "UserScenarios",
          },
          {
            path: "JourneyMap",
            component: () =>
              import(
                /* webpackChunkName: "Define" */ `../views/Playbook/Define/JourneyMap.vue`
              ),
            name: "JourneyMap",
          },
        ],
      },
      {
        path: "Ideate",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Ideate.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Ideate" */ `../views/Playbook/Ideate/Overview.vue`
              ),
            name: "Ideate",
          },
          {
            path: "CardSorting",
            component: () =>
              import(
                /* webpackChunkName: "Ideate" */ `../views/Playbook/Ideate/CardSorting.vue`
              ),
            name: "CardSorting",
          }, 
          {
            path: "HowMightWeStatements",
            component: () =>
              import(
                /* webpackChunkName: "Ideate" */ `../views/Playbook/Ideate/HowMightWeStatements.vue`
              ),
            name: "HowMightWeStatements",
          },
          {
            path: "DesignAndPrototype",
            component: () =>
              import(
                /* webpackChunkName: "Ideate" */ `../views/Playbook/Ideate/DesignAndPrototype.vue`
              ),
            name: "DesignAndPrototype",
          },
        ],
      },
      {
        path: "Validate",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Validate.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Validate" */ `../views/Playbook/Validate/Overview.vue`
              ),
            name: "PlaybookValidate",
          },
          {
            path: "UsabilityTesting",
            component: () =>
              import(
                /* webpackChunkName: "Validate" */ `../views/Playbook/Validate/UsabilityTesting.vue`
              ),
            name: "UsabilityTesting",
          },
          {
            path: "UXSelfAudit",
            component: () =>
              import(
                /* webpackChunkName: "Validate" */ `../views/Playbook/Validate/UXSelfAudit.vue`
              ),
            name: "UXSelfAudit",
          },
        ],
      },
      {
        path: "Implement",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Implement.vue`
          ),
        name: "Implement",
      },
      {
        path: "Measure",
        component: () =>
          import(
            /* webpackChunkName: "PlaybookSub" */ `../views/Playbook/Measure.vue`
          ),
        name: "Measure",
      },
    ],
  },
  {
    path: "/About",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "About" */ `../views/About.vue`),
  },
  {
    path: "/Community",
    name: "Community",
    component: () =>
      import(/* webpackChunkName: "Community" */ `../views/Community.vue`),
  },
  {
    path: "/Tools",
    name: "Tools",
    component: () =>
      import(/* webpackChunkName: "Tools" */ `../views/Tools.vue`),
  },
  {
    path: "/Faq",
    name: "Faq",
    component: () => import(/* webpackChunkName: "Faq" */ `../views/Faqs.vue`),
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ `../views/Contact.vue`),
  },
  {
    path: "/Release",
    name: "Release",
    component: () =>
      import(/* webpackChunkName: "Release" */ `../views/Release.vue`),
  },
  {
    path: "/Sitemap",
    name: "Sitemap",
    component: () =>
      import(/* webpackChunkName: "Sitemap" */ `../views/Sitemap.vue`),
  },
  {
    path: "/SupportedBrowsers",
    name: "SupportedBrowsers",
    component: () =>
      import(/* webpackChunkName: "Sitemap" */ `../views/SupportedBrowsers.vue`),
  },
  {
    path: "/Search/:searchValue?",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "Search" */ `../views/Search.vue`),
  },
  {
    path: "/MobileStandards",
    component: () =>
      import(/* webpackChunkName: "MobileStandards" */ `../views/MobileStandards.vue`),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "MobileStandards" */ `../views/MobileStandards/Overview.vue`
          ),
        name: "Overview",
      },
      {
        path: "Styles",
        component: () =>
          import(
            /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles.vue`),
            children: [
              {
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Overview.vue`
                  ),
                name: "Overview",
              },
              {
                path: "Colors",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Colors.vue`
                  ),
                name: "Colors",
              },
              {
                path: "Icons",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Icons.vue`
                  ),
                name: "Icons",
              },
              {
                path: "Layout",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Layout.vue`
                  ),
                name: "Layout",
              },
              {
                path: "Typography",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Typography.vue`
                  ),
                name: "Typography",
              },
              {
                path: "Spacing",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Styles/Spacing.vue`
                  ),
                name: "Spacing",
              }
            ],
      },
      {
        path: "ButtonsLinks",
        component: () =>
          import(
            /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/ButtonsLinks.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ `../views/MobileStandards/ButtonsLinks/Overview.vue`
              ),
            name: "Overview",
          },
          {
            path: "Button",
            component: () =>
              import(
                /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/ButtonsLinks/Button.vue`
              ),
            name: "Button",
          },
          {
            path: "Link",
            component: () =>
              import(
                /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/ButtonsLinks/Link.vue`
              ),
            name: "Link",
          }

        ]
      },
      {
        path: "FormControls",
        component: () =>
          import(
            /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/FormControls.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ `../views/MobileStandards/FormControls/Overview.vue`
              ),
            name: "Overview",
          },
          {
            path: "Checkbox",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Checkbox.vue`
              ),
            name: "Checkbox",
          },
          {
            path: "Datepicker",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Datepicker.vue`
              ),
            name: "Datepicker",
          },
          {
            path: "Dropdown",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Dropdown.vue`
              ),
            name: "Dropdown",
          },
          {
            path: "Fileupload",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Fileupload.vue`
              ),
            name: "Fileupload",
          },
          {
            path: "IconFormField",
            component: () =>
              import(
                /* webpackChunkName: "IconFormField" */ `../views/MobileStandards/FormControls/IconFormField.vue`
              ),
            name: "Fileupload",
          },
          {
            path: "Radiobutton",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Radiobutton.vue`
              ),
            name: "Radiobutton",
          },
          {
            path: "SegmentedControl",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/SegmentedControl.vue`
              ),
            name: "SegementedControl",
          },
          {
            path: "Slider",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Slider.vue`
              ),
            name: "Slider",
          },
          {
            path: "Toggleswitch",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Toggleswitch.vue`
              ),
            name: "Toggleswitch",
          },
          {
            path: "Textfield",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/FormControls/Textfield.vue`
              ),
            name: "Textfield",
          },
        ]
      },
      {
        path: "Navigation",
        component: () =>
          import(
            /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/Navigation.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ `../views/MobileStandards/Navigation/Overview.vue`
              ),
            name: "Overview",
          },
          {
            path: "Navigation",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Navigation/Navigation.vue`
              ),
            name: "Navigation",
          },
          {
            path: "Pagination",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Navigation/Pagination.vue`
              ),
            name: "Pagination",
          },
          {
            path: "Stepindicator",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Navigation/Stepindicator.vue`
              ),
            name: "Stepindicator",
          },
          {
            path: "Tabbar",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Navigation/Tabbar.vue`
              ),
            name: "Tabbar",
          },
        ]
      },
      {
        path: "Layout",
        component: () =>
          import(
            /* webpackChunkName: "ButtonsLinks" */ `../views/MobileStandards/Layout.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ `../views/MobileStandards/Layout/Overview.vue`
              ),
            name: "Overview",
          },
          {
            path: "Accordion",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Layout/Accordion.vue`
              ),
            name: "Accordion",
          },
          {
            path: "Cards",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Layout/Cards.vue`
              ),
            name: "Cards",
          },
          {
            path: "GridCards",
            component: () =>
              import(
                /* webpackChunkName: "GridCards" */ `../views/MobileStandards/Layout/GridCards.vue`
              ),
            name: "Cards",
          },
          {
            path: "NavigationCard",
            component: () =>
              import(
                /* webpackChunkName: "NavigationCard" */ `../views/MobileStandards/Layout/NavigationCard.vue`
              ),
            name: "NavigationCard",
          },
          {
            path: "PassCard",
            component: () =>
              import(
                /* webpackChunkName: "PassCard" */ `../views/MobileStandards/Layout/PassCard.vue`
              ),
            name: "PassCard",
          },
          {
            path: "Search",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Layout/Search.vue`
              ),
            name: "Search",
          },
          {
            path: "Splashscreen",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Layout/Splashscreen.vue`
              ),
            name: "Splashscreen",
          },
          {
            path: "Tab",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Layout/Tab.vue`
              ),
            name: "Tab",
          },
        ]
      },
      {
        path: "Alert",
        component: () =>
          import(
            /* webpackChunkName: "Components" */ `../views/MobileStandards/Alert.vue`
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Components" */ `../views/MobileStandards/Alert/Overview.vue`
              ),
            name: "Overview",
          },
          
          {
            path: "Alert",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Alert.vue`
              ),
            name: "Alert",
          },
          {
            path: "Banner",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Banner.vue`
              ),
            name: "Banner",
          },
          {
            path: "Dialog",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Dialog.vue`
              ),
            name: "Dialog",
          },
          
        
          {
            path: "Modal",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Modal.vue`
              ),
            name: "Modal",
          },
          {
            path: "Notification",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Notification.vue`
              ),
            name: "Notification",
          },
          {
            path: "Spinner",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Spinner.vue`
              ),
            name: "Spinner",
          },
          {
            path: "Status",
            component: () =>
              import(
                /* webpackChunkName: "Status" */ `../views/MobileStandards/Alert/Status.vue`
              ),
            name: "Status",
          },
          {
            path: "Tooltip",
            component: () =>
              import(
                /* webpackChunkName: "Discover" */ `../views/MobileStandards/Alert/Tooltip.vue`
              ),
            name: "Tooltip",
          }
        ],
      },
      {
        path: "Templates",
        component: () =>
          import(
            /* webpackChunkName: "Templates" */ `../views/MobileStandards/Templates.vue`),
            children: [
              {
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Templates" */ `../views/MobileStandards/Templates/Overview.vue`
                  ),
                name: "Overview",
              },
              {
                path: "Acknowledgement",
                component: () =>
                  import(
                    /* webpackChunkName: "Templates" */ `../views/MobileStandards/Templates/Acknowledgement.vue`
                  ),
                name: "Acknowledgement",
              },
              {
                path: "About",
                component: () =>
                  import(
                    /* webpackChunkName: "Templates" */ `../views/MobileStandards/Templates/About.vue`
                  ),
                name: "About",
              },
              {
                path: "Authentication",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Authentication.vue`
                  ),
                name: "Authentication",
              },
              {
                path: "Content",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Content.vue`
                  ),
                name: "Content",
              },
              {
                path: "Filters",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Filters.vue`
                  ),
                name: "Filters",
              },
              {
                path: "SingleStepForm",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/SingleStepForm.vue`
                  ),
                name: "SingleStepForm",
              },
              {
                path: "MultiStepForm",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/MultiStepForm.vue`
                  ),
                name: "MultiStepForm",
              },
              {
                path: "HamburgerMenu",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/HamburgerMenu.vue`
                  ),
                name: "HamburgerMenu",
              },
              {
                path: "LandingPage",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/LandingPage.vue`
                  ),
                name: "LandingPage",
              },
              {
                path: "ListingPage",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/ListingPage.vue`
                  ),
                name: "ListingPage",
              },
              {
                path: "Myinfo",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Myinfo.vue`
                  ),
                name: "Myinfo",
              },
              {
                path: "Payment",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Payment.vue`
                  ),
                name: "Payment",
              },
              {
                path: "Settings",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Settings.vue`
                  ),
                name: "Settings",
              },
              {
                path: "Summary",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Summary.vue`
                  ),
                name: "Summary",
              },
              {
                path: "Onboarding",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Onboarding.vue`
                  ),
                name: "Onboarding",
              },
              {
                path: "Default",
                component: () =>
                  import(
                    /* webpackChunkName: "Styles" */ `../views/MobileStandards/Templates/Default.vue`
                  ),
                name: "Default",
              },
              {
                path: "Notification",
                component: () =>
                  import(
                    /* webpackChunkName: "Notification" */ `../views/MobileStandards/Templates/Notification.vue`
                  ),
                name: "Notification",
              }
            ],
      },
      {
        path: "BestPractices",
        component: () =>
          import(
            /* webpackChunkName: "BestPractices" */ `../views/MobileStandards/BestPractices.vue`),
            children: [
              {
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "BestPractices" */ `../views/MobileStandards/BestPractices/Overview.vue`
                  ),
                name: "BestPractices",
              },
            ]
      }
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.getElementById("app").scrollIntoView();
    return null;
  },
});

export default router;
