<template>
  <div :class="['DxpDescriptionImageCard']">
    <div v-if="imageUrl" class="DxpDescriptionImageCard__ImageContent">
      <figure
        v-if="isSvg"
        class="DxpDescriptionImageCard__ImageSvg"
        v-html="imageUrl"
      ></figure>
      <mom-image
        v-else
        class="DxpDescriptionImageCard__Image"
        :source="imageUrl"
        alt="Why use DX+"
      />
    </div>
    <br /><br />
    <div v-if="title" class="DxpDescriptionImageCard__Header">
      <div class="DxpDescriptionImageCard__TitleWrapper">
        <h3 class="mom-h3 DxpDescriptionImageCard__Title">
          {{ title }}
        </h3>
      </div>
    </div>

    <div class="DxpDescriptionImageCard__Contain">
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import { MomImage } from "cdpl/image.esm";

export default {
  name: "DxpDescriptionImageCard",
  release: "0.1.0",
  lastUpdated: "0.2.1",
  components: {
    MomImage,
  },
  props: {
    /**
     * If image is svg
     */
    isSvg: {
      type: Boolean,
      default: false,
    },
    /**
     * Card content
     */
    text: {
      type: String,
    },

    /**
     * Card title
     */
    title: {
      type: String,
    },
    /**
     * Card Image path
     */
    imageUrl: {
      type: String,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.DxpDescriptionImageCard {
  background-color: $color-white;
  overflow: hidden;
  padding-top: rem(32);

  @include min-width(tablet) {
    padding: rem(18) rem(24);
  }

  @media print {
    box-shadow: none;
    padding: rem(0);
  }
}

.DxpDescriptionImageCard__Image {
  max-height: component-width(s);
  margin-bottom: rem(33);
}

.DxpDescriptionImageCard__ImageSvg {
  max-height: component-width(s);
  margin-bottom: rem(33);
}

.DxpDescriptionImageCard__ImageContent {
  display: flex;
  justify-content: center;
}

.DxpDescriptionImageCard__Header {
  display: flex;
  margin-bottom: rem(16);
  justify-content: center;
}

.DxpDescriptionImageCard__Title {
  margin-bottom: 0;
  word-break: break-word;
  text-align: center;
}

.DxpDescriptionImageCard__Contain {
  justify-content: center;
  text-align: center;
}
</style>
