<template>
  <div :class="['DxpDescriptionImageCardCustom']">
    <div class="DxpDescriptionImageCardCustom__PreCard">
      <!-- @slot Pre Card content -->
      <slot name="precard"></slot>
    </div>

    <div v-if="imageUrl" class="DxpDescriptionImageCardCustom__ImageContent">
      <figure
        v-if="isSvg"
        class="DxpDescriptionImageCardCustom__Image"
        v-html="imageUrl"
      ></figure>
      <mom-image
        v-else
        class="DxpDescriptionImageCardCustom__Image"
        :source="imageUrl"
        alt="This is an image"
      />
    </div>
    <div v-if="title" class="DxpDescriptionImageCardCustom__Header">
      <div class="DxpDescriptionImageCardCustom__TitleWrapper">
        <h3 class="mom-h3 DxpDescriptionImageCardCustom__Title">
          {{ title }}
        </h3>
      </div>
    </div>

    <div>
      <div class="DxpDescriptionImageCardCustom__Contain">
        <!-- @slot Card content -->
        <slot></slot>
      </div>
    </div>

    <div class="DxpDescriptionImageCardCustom__Button">
      <mom-button
        v-if="showButton"
        class="DxpDescriptionImageCardCustom__Action DxpButton"
        :text="buttonText"
        @click="onContinue"
      />
    </div>
  </div>
</template>

<script>
import { MomLink } from "cdpl/link.esm";
import { MomButton } from "cdpl/button.esm";
import { MomImage } from "cdpl/image.esm";

export default {
  name: "DxpDescriptionImageCardCustom",
  release: "0.1.0",
  lastUpdated: "0.2.1",
  components: {
    MomButton,
    MomImage,
  },
  props: {
    /**
     * Card title
     */
    title: {
      type: String,
    },
    /**
     * Is image or svg
     */
    isSvg: {
      type: Boolean,
      default: false,
    },
    /**
     * Card Image path
     */
    imageUrl: {
      type: String,
    },
    /**
     * Show Footer Button
     */
    showButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Footer Button Label
     */
    buttonText: {
      type: String,
      default: "Know more",
    },
  },
  methods: {
    onContinue() {
      /**
       * When the continue button is clicked
       *
       * @event continue
       */
      this.$emit("continue");
    },
  },
};
</script>

<style lang="scss" scoped>
.DxpDescriptionImageCardCustom {
  overflow: hidden;
  justify-content: center;
  display: inline;

  @include min-width(tablet) {
    padding: rem(18) rem(44);
  }

  @media print {
    box-shadow: none;
    padding: rem(0);
  }
}

.DxpDescriptionImageCardCustom__Image {
  max-height: component-width(l);
}

.DxpDescriptionImageCardCustom__PreCard {
  padding: rem(16) rem(0);
}

.DxpDescriptionImageCardCustom__Header {
  display: flex;
  margin-bottom: rem(16);
}

.DxpDescriptionImageCardCustom__Title {
  margin-bottom: 0;
  word-break: break-word;
  margin-top: rem(32);
}

.DxpDescriptionImageCardCustom__Contain {
  min-height: rem(160);
  padding-bottom: rem(16);
  overflow: hidden;
  display: flex;
}

.DxpDescriptionImageCardCustom__Button {
  margin: rem(16) rem(0);
}
</style>
