<template>
  <div class="DxpLandingPage">
    <div class="DxpLandingPage__Wrapper">
      <dxp-default-layout
        currentHeaderTab="-1"
        isEmptyBody
        :showBreadcrumbs="false"
        @feedback="$router.push('/Community')"
      >
        <div>
          <mom-layout-wrapper>
            <div class="DxpLandingPage__Carousel">
              <dxp-card-carousel
                :isSvg="true"
                width="2500"
                height="339"
                :images="[
                  {
                    url: getImgSvg(
                      'main-banner-illustration.svg',
                      'Design better experiences',
                      '401',
                      '344'
                    ),
                    link: '/GettingStarted',
                    alt: 'Design better experiences',
                    title: 'Design better experiences',
                    buttonLabel: 'Get started',
                    text:
                      'Think like a designer with DX+ — MOM’s design system that gives you the tools you need to include design thinking in your work. Create designs that are beautiful, functional and meet your users’ needs.',
                  },
                ]"
              >
              </dxp-card-carousel>
            </div>
          </mom-layout-wrapper>

          <mom-layout-wrapper>
            <div class="DxpLandingPage__Cards__Title">
              <h2 class="mom-h2">What is in DX+?</h2>
            </div>
          </mom-layout-wrapper>

          <div class="DxpLandingPage__Cards">
            <mom-layout-wrapper>
              <div class="DxpLandingPage__Cards__Content">
                <mom-grid-container class="DxpLandingPage__GridContainer">
                  <mom-grid-column
                    size-lg="3"
                    size="12"
                    v-for="(card, i) in cardsRow1"
                    :key="`card_row1_${i}`"
                    :id="`card_row1_${i}`"
                  >
                    <mom-card
                      class="DxpLandingPage__GridCard"
                      :title="card.title"
                      variant="action"
                      :buttonText="card.buttonText"
                      buttonIconPosition="right"
                      buttonType="link"
                      :buttonIcon="card.buttonIcon"
                      :buttonLink="card.url"
                      :buttonLinkTarget="card.target"
                      :buttonRel="card.rel"
                      :buttonDisabled="card.isDisabled"
                    >
                      <p class="mom-p">
                        {{ card.text }}
                      </p>
                    </mom-card>
                  </mom-grid-column>
                  <mom-grid-column size-lg="3"> </mom-grid-column>
                  <mom-grid-column
                    size-lg="3"
                    size="12"
                    v-for="(card, i) in cardsRow2"
                    :key="`card_row2_${i}`"
                    :id="`card_row2_${i}`"
                  >
                    <mom-card
                      class="DxpLandingPage__GridCard"
                      :title="card.title"
                      variant="action"
                      buttonIconPosition="right"
                      buttonType="link"
                      :buttonIcon="card.buttonIcon"
                      :buttonText="card.buttonText"
                      :buttonLink="card.url"
                      :buttonLinkTarget="card.target"
                      :buttonRel="card.rel"
                      :buttonDisabled="card.isDisabled"
                    >
                      <p class="mom-p">
                        {{ card.text }}
                      </p>
                    </mom-card>
                  </mom-grid-column>
                </mom-grid-container>
              </div>
            </mom-layout-wrapper>
          </div>

          <mom-layout-wrapper>
            <div class="DxpLandingPage__ImageCards__Title">
              <h2 class="mom-h2 DxpLandingPage__Heading2">
                Why use DX+?
              </h2>
            </div>
          </mom-layout-wrapper>

          <mom-layout-wrapper>
            <div class="DxpLandingPage__ImageCards">
              <div class="DxpLandingPage__ImageCards__Content">
                <mom-grid-container
                  class="DxpLandingPage__ImageCards__GridContainer"
                >
                  <mom-grid-column size-lg="3" size="12">
                    <dxp-description-image-card
                      isSvg
                      title="One source of truth"
                      text="Having all components and templates in one place ensures that everything you design in MOM looks consistent."
                      :imageUrl="getImgSvg(cardIconUrl1, 'One source of truth', '259', '243')"
                    >
                    </dxp-description-image-card>
                  </mom-grid-column>
                  <mom-grid-column size-lg="3" size="12">
                    <dxp-description-image-card
                      isSvg
                      title="User-centric"
                      text="Make use of design thinking processes in the playbook to ideate and validate your designs."
                      :imageUrl="getImgSvg(cardIconUrl2, 'Better requirements gathering', '259', '243')"
                    >
                    </dxp-description-image-card>
                  </mom-grid-column>
                  <mom-grid-column size-lg="3" size="12">
                    <dxp-description-image-card
                      isSvg
                      title="Design faster"
                      text="We made prototyping easier so that you can focus on creating the best experience for your users."
                      :imageUrl="getImgSvg(cardIconUrl3, 'Faster development', '259', '243')"
                    >
                    </dxp-description-image-card>
                  </mom-grid-column>
                  <mom-grid-column size-lg="3" size="12">
                    <dxp-description-image-card
                      isSvg
                      title="Compliance ready"
                      text="Ensure that your designs follow the Digital Services Standard and Web Content Accessibility Guidelines."
                      :imageUrl="getImgSvg(cardIconUrl4, 'Compliance ready', '259', '243')"
                    >
                    </dxp-description-image-card>
                  </mom-grid-column>
                </mom-grid-container>
              </div>
            </div>
          </mom-layout-wrapper>

          <!-- commenting until we have content later during training -->
          <!-- <div class="DxpLandingPage__ArticleCards">
            <div class="DxpLandingPage__ArticleCards__Title">
              <h2 class="mom-h2 DxpLandingPage__Heading2">
                Learn Prototyping with Figma
              </h2>
            </div>
            <mom-layout-wrapper>
              <div class="DxpLandingPage__ArticleCards__Content">
                <mom-grid-container
                  class="DxpLandingPage__ArticleCards__GridContainer"
                >
                  <mom-grid-column
                    size-lg="6"
                    size="12"
                    class="DxpLandingPage__ArticleCards__GridCol"
                  >
                    <dxp-description-image-card-custom
                      show-button
                      title="Idea to prototype in 20mins"
                      isSvg
                      :imageUrl="
                        checkMediaSize()
                          ? getImgSvg(descriptionCardImageUrl, '640', '180')
                          : getImgSvg(descriptionCardImageUrl, '640', '362')
                      "
                    >
                      <div>
                        <p>A quick virtual training on:</p>
                        <br />
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Choosing components and
                          templates
                        </p>
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Adding DX+ to your
                          projects
                        </p>
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Creating screen
                          designs
                        </p>
                      </div>
                    </dxp-description-image-card-custom>
                  </mom-grid-column>
                  <mom-grid-column
                    size-lg="6"
                    size="12"
                    class="DxpLandingPage__ArticleCards__GridCol"
                  >
                    <dxp-description-image-card-custom
                      show-button
                      title="Using Figma designs to code"
                      isSvg
                      :imageUrl="
                        checkMediaSize()
                          ? getImgSvg(descriptionCardImageUrl, '640', '180')
                          : getImgSvg(descriptionCardImageUrl, '640', '362')
                      "
                    >
                      <div>
                        <p>A quick virtual training on:</p>
                        <br />
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Choosing components and
                          templates
                        </p>
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Adding DX+ to your
                          projects
                        </p>
                        <p class="DxpLandingPage__ArticleCards__ListItem">
                          <mom-icon icon="add" size="s" /> Creating screen
                          designs
                        </p>
                      </div>
                    </dxp-description-image-card-custom>
                  </mom-grid-column>
                </mom-grid-container>
              </div>
            </mom-layout-wrapper>
          </div> -->

          <!-- commenting until we have content later during phase 2 -->
          <!-- <mom-layout-wrapper class="DxpLandingPage__ProjectCards">
            <div>
              <div class="DxpLandingPage__ProjectCards__Content">
                <mom-grid-container
                  class="DxpLandingPage__ProjectCards__GridContainer"
                >
                  <mom-grid-column
                    size="12"
                    size-lg="4"
                    class="DxpLandingPage__ProjectCards__GridCol"
                  >
                    <dxp-description-image-card-custom show-button>
                      <div slot="precard">
                        <p class="mom-p">SHOWCASE PROJECTS</p>
                      </div>
                      <div>
                        <p class="DxpLandingPage__Heading">
                          iWorkhealth and their DX+ journey
                        </p>
                        <br />
                        <p class="DxpLandingPage__ProjectCards__ListItem">
                          This is the first Important point Yet another point
                          that cannot be missed How can we live without this one
                        </p>
                      </div>
                    </dxp-description-image-card-custom>
                  </mom-grid-column>
                  <mom-grid-column size-lg="3"></mom-grid-column>
                  <mom-grid-column
                    size="12"
                    size-lg="5"
                    class="DxpLandingPage__ProjectCards__GridColImage"
                  >
                    <figure
                      v-html="
                        getImgSvg(
                          'home-showcase-illustration.svg',
                          '485',
                          '476'
                        )
                      "
                    />
                  </mom-grid-column>
                </mom-grid-container>
              </div>
            </div>
          </mom-layout-wrapper> -->
        </div>
      </dxp-default-layout>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DOMPurify from "dompurify";
import DxpDefaultLayout from "@/components/page/DxpDefaultLayout.vue";
import DxpCardCarousel from "@/components/card/DxpCardCarousel.vue";
import DxpDescriptionImageCard from "@/components/card/DxpDescriptionImageCard.vue";
import DxpDescriptionImageCardCustom from "@/components/card/DxpDescriptionImageCardCustom.vue";
import { MomLayoutWrapper } from "cdpl/layout.esm";
import { MomGridContainer, MomGridColumn } from "cdpl/gridsystem.esm";
import { MomIcon } from "cdpl/icon.esm";
import { MomCard } from "cdpl/mom_card.esm";
import imageActions from "@/utils/imageActions";

export default {
  name: "Home",
  release: "0.1.1",
  lastUpdated: "0.1.1",
  title: "MOM | Digital Experience Plus DX+",
  description: "Design Experience Plus (DX+) is a new initiative by ISTD(MOM) to create one-MOM design system. With DX+, you can make use of design thinking methods and tools to solve complex problems creatively.",
  components: {
    DxpDefaultLayout,
    DxpCardCarousel,
    MomLayoutWrapper,
    MomGridContainer,
    MomGridColumn,
    MomIcon,
    MomCard,
    DxpDescriptionImageCard,
    DxpDescriptionImageCardCustom,
  },
  mixins: [imageActions],
  data() {
    return {
      currentCardIndex: 0,
      cardIconUrl1: "one-source-illustration.svg",
      cardIconUrl2: "reg-gathering-illustration.svg",
      cardIconUrl3: "faster-development-illustration.svg",
      cardIconUrl4: "compliance-illustration.svg",
      descriptionCardImageUrl: "Rectangle191x.svg",
      cardsRow1: [
        {
          title: "Playbook",
          buttonText: "Learn more",
          url: "#/Playbook",
          text:
            "Learn to use the MOM digital design process in your projects with a step-by-step guide.",
          isDisabled: false,
          buttonIcon: "chevron-right"
        },
        {
          title: "Components",
          buttonText: "View components",
          url: "/components/index.html",
          text:
            "Check the guidelines on when and where to use our styles and components.",
          isDisabled: false,
          buttonIcon: "chevron-right"
        },
        {
          title: "Templates",
          buttonText: "View templates",
          url: "/templates/index.html",
          text:
            "Use some of our common page layouts, sorted by user scenarios.",
          isDisabled: false,
          buttonIcon: "chevron-right"
        },
        {
          title: "Prototyping",
          buttonText: "Start designing (Figma)",
          url: "https://www.figma.com/",
          target: "_blank",
          rel: "noreferrer",
          text:
            "Prototype user flows and design screens quickly with our components and style on Figma.",
          isDisabled: false,
          buttonIcon: "open-in-new"
        },
      ],
      cardsRow2: [
        {
          title: "Community",
          buttonText: "Join us on Slack",
          target: "_blank",
          rel: "noreferrer",
          url: "https://dxplus.slack.com/",
          text:
            "Connect with other designers. Ask questions, report bugs, request for new features and more.",
          isDisabled: false,
          buttonIcon: "open-in-new"
        },
        {
          title: "UX metrics",
          buttonText: "UX metrics dashboard",
          target: "_blank",
          rel: "noreferrer",
          url: "https://datastudio.google.com/u/0/reporting/1feb0dc8-abd2-4f0b-a865-20e49eabb127/page/NaN1",
          text:
            "Measure user behaviour on your digital services with our UX metric tool.",
          isDisabled: false,
          buttonIcon: "open-in-new"
        },
        {
          title: "UI checker",
          buttonText: "Check your code",
          target: "_blank",
          rel: "noreferrer",
          url: "https://uichecker.com/",
          text:
            "Upload your code to check if your designs follow design standards and best practices.",
          isDisabled: false,
          buttonIcon: "open-in-new"
        },
      ],
    };
  },
  methods: {
    checkMediaSize() {
      let isMobile = window.matchMedia("(max-width: 48rem)");
      return isMobile.matches;
    },
    updateCard(i) {
      this.$refs.image.classList.add("DxpCardCarousel__Image--hidden");
      setTimeout(() => {
        this.currentImageIndex = i;
        this.scale = 1;
        this.translateX = 0;
        this.translateY = 0;
        this.$refs.image.classList.remove("DxpCardCarousel__Image--hidden");
      }, 250);
    },
  },
};
</script>

<style scoped lang="scss">
.DxpLandingPage__Heading {
  @include font-size(xl);
  @include line-height(xl);
  @include font-weight(semibold);
  padding-bottom: rem(16);
}

.DxpLandingPage__Heading2 {
  text-align: center;
}

.DxpLandingPage__Paragraph {
  padding-right: rem(24);
  @include font-size(xl);
  @include line-height(xl);
  @include font-weight(semibold);
}

.DxpLandingPage__Carousel {
  background-color: $color-white;
  width: 100%;
  padding-top: rem(16);

  @include min-width(tablet) {
    padding-top: rem(48);
  }
}

.DxpLandingPage__Wrapper {
  background-color: $color-white;
}

.DxpLandingPage__Cards {
  margin-bottom: rem(81);
  background-color: $color-white;
  padding-top: rem(32);

  @include min-width(tablet) {
    margin-top: rem(32);
    background: url("../assets/images_local/Group232.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.DxpLandingPage__Cards--mobile {
  display: block;

  @include min-width(tablet) {
    display: none !important;
  }
}

.DxpLandingPage__Cards__Content {
  @include min-width(tablet) {
    display: block !important;
    padding-left: rem(64);
    padding-right: rem(64);
    padding-left: rem(0);
    padding-right: rem(0);
    width: 100%;
    position: relative;
  }
}

.DxpLandingPage__ImageCards__Content {
  justify-content: center;
  @include min-width(tablet) {
    padding-left: rem(32);
    padding-right: rem(32);
    margin-bottom: rem(131);
    min-height: max-content;
  }
}

.DxpLandingPage__GridContainer {
  padding-bottom: rem(20.8);
}

.DxpLandingPage__Cards__Title {
  padding-top: rem(136);
  padding-bottom: rem(8);
  margin-top: rem(112);
}

.DxpLandingPage__ImageCards__Title {
  display: flex;
  margin-top: rem(48);
  margin-bottom: rem(32);
  justify-content: center;
}

.DxpLandingPage__ArticleCards {
  background-color: $color-purple-00;
  margin-top: rem(16);

  @include min-width(tablet) {
    margin-top: rem(0);
  }
  // @include min-width(tablet) {
  //   background: url("../assets/images_local/Rectangle21.png");
  //   background-size: cover;
  //   background-repeat: no-repeat;
  // }
}

.DxpLandingPage__ArticleCards__Title {
  padding-top: rem(32);
  justify-content: center;
  display: flex;
}

.DxpLandingPage__ArticleCards__ListItem {
  padding-bottom: rem(8);
}

.DxpLandingPage__ArticleCards__GridCol {
  display: flex;
  justify-content: center;
}

.DxpLandingPage__ProjectCards__GridColImage {
  display: flex;
  justify-content: flex-end;
}

.DxpLandingPage__ArticleCards__Content {
  display: flex;
  justify-content: center;
  padding: rem(8) rem(32);
}

.DxpLandingPage__ProjectCards {
  padding-top: rem(48) !important;
  background-color: $color-white;
}

.DxpLandingPage__ProjectCards__Content {
  display: flex;
  justify-content: center;
  padding: rem(32);
}

.DxpLandingPage__GridCard {
  width: 100%;
}
</style>

<style lang="scss">
.DxpLandingPage__GridCard .contain {
  min-height: rem(50);

  @include min-width(tablet) {
    min-height: rem(118.4);
  }
}
</style>
